<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      size="60%"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-button @click="addDataInfo" style="margin:0px 10px">新增</el-button>
          </el-col>
          <el-col :span="6">
            <el-input v-model="changAddress.address" placeholder="输入地址" disabled />
          </el-col>
          <el-col :span="2">
            <el-button @click="changeAddress" disabled>转化</el-button>
          </el-col>
          <el-col :span="6">
            <el-input v-model="changAddress.coordinate" placeholder="输入经纬度" disabled />
          </el-col>
        </el-row>
        <el-table height="600" :data="dataInfoList" border stripe style="width:100%">
          <el-table-column label="序号" :index="indexMethod" fixed type="index" width="50" />
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="120"
            fixed
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-if="!scope.row.editShow"
                @click.native.prevent="addEditDetailButton(scope.$index, dataInfoList)"
              >保存</el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-else
                @click.native.prevent="editButton(scope.$index, dataInfoList)"
              >编辑</el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click.native.prevent="deleteRow(scope.$index, dataInfoList)"
              >删除</el-button>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="type" width="110" align="center">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.type"
                filterable
                :disabled="scope.row.id!=null?true:false"
                placeholder="请选择"
                @change="(val) => checkChange(val, scope.row)"
              >
                <el-option
                  v-for="item in typeDetailList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="类别" prop="category" align="center" width="120">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.category"
                filterable
                placeholder="请选择"
                :disabled="scope.row.editShow"
                @change="(val) => checkCategoryChange(val, scope.row)"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="名称" prop="name" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.name"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <el-table-column label="详细地址" prop="address" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.address"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <el-table-column label="经度" prop="longitude" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.longitude"
                :disabled="scope.row.editShow"
                placeholder="经度"
              />
            </template>
          </el-table-column>
          <el-table-column label="纬度" prop="latitude" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.latitude"
                :disabled="scope.row.editShow"
                placeholder="纬度"
              />
            </template>
          </el-table-column>
          <!-- <el-table-column label="标签" prop="title" width="120">
            <template slot-scope="scope">
              <template>
                <el-input class="edit-input" v-model="scope.row.title" placeholder="请输入内容" />
              </template>
            </template>
          </el-table-column>-->

          <el-table-column label="介绍" prop="description" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.description"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <el-table-column label="平均预算费用" prop="avgBudget" width="150" align="center">
            <template slot-scope="scope">
              <el-input-number
                class="edit-input"
                size="medium"
                v-model="scope.row.avgBudget"
                controls-position="right"
                :disabled="scope.row.editShow"
                style="width: 120px;"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <el-table-column label="相关链接" prop="relatedLinks" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.relatedLinks"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>

          <el-table-column label="评分等级" prop="ratingScale" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.ratingScale"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>

          <el-table-column label="联系方式" prop="contact" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.contact"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>

          <!-- <el-table-column label="游玩提示" prop="title" width="120">
            <template slot-scope="scope">
              <template>
                <el-input class="edit-input" v-model="scope.row.title" placeholder="请输入内容" />
              </template>
            </template>
          </el-table-column>-->

          <el-table-column label="时令" prop="seasonal" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.seasonal"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
          <!-- <el-table-column label="状态" prop="title" width="120">
            <template slot-scope="scope">
              <template>
                <el-input class="edit-input" v-model="scope.row.title" placeholder="请输入内容" />
              </template>
            </template>
          </el-table-column>-->
        </el-table>

        <!-- <div class="demo-drawer__footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="addFormButton()">确 定</el-button>
        </div>-->
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
import {
  addDataApi,
  editDataApi,
  getPageListApi,
  deleteByIdApi
} from "@/api/life/trip/tripDetail";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: "新增",
      addEditForm: {},
      timer: null,
      ids: null,
      dataInfoList: [],
      tripId: null,
      typeDetailList: [],
      categoryList: [],
      changAddress: {}
    };
  },
  methods: {
    show(tripId) {
      this.typeDetailList = this.$parent.typeDetailList;
      this.dialog = true;
      this.tripId = tripId;
      this.selectByIdButton();
      this.initDataList();
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.dataInfoList = [];
      this.$parent.selectListTripButton();
    },
    addFormButton() {},
    //查询详情
    selectByIdButton() {
      getPageListApi({ tripId: this.tripId }).then(res => {
        console.log(res);
        this.dataInfoList = res.result.records;
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    deleteRow(index, rows) {
      if (rows[index].id != null && rows[index].id != undefined) {
        //需要调用删除接口
        deleteByIdApi(rows[index].id).then(res => {
          this.selectByIdButton();
        });
      } else {
        rows.splice(index, 1);
      }
    },
    addDataInfo() {
      var dataOneInfo = {
        id: null,
        editShow: false,
        tripId: this.tripId
      };
      this.dataInfoList.push(dataOneInfo);
    },
    initDataList() {
      this.categoryList = this.$parent.categoryDetailList;
      //加载类型 typeDetailList
    },
    checkChange(val, row) {
      let categoryLists = this.typeDetailList.filter(item => item.key == val);
      this.categoryList = categoryLists[0].children;
      this.$set(row, "category", null);
    },
    checkCategoryChange(val, row) {},
    addEditDetailButton(row, val) {
      if (val[row].id != null) {
        editDataApi(val[row]).then(res => {
          this.selectByIdButton();
        });
      } else {
        addDataApi(val[row]).then(res => {
          this.selectByIdButton();
        });
      }
      this.initDataList();
    },
    editButton(row, val) {
      val[row].editShow = false;
      let categoryLists = this.typeDetailList.filter(
        item => item.key == val[row].type
      );
      console.log(categoryLists);
      this.categoryList = categoryLists[0].children;
    },
    changeAddress() {}
  }
};
</script>
<style scoped>
</style>



