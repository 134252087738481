import { getAction, getByIdAction, postAction, putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/live/tripDetail/v1'
//调用 import { getTableLists } from "@/api/reportFrom/repost";


const getPageListApi = (params) => getAction(baseURL + '/pageList', params);

const getSelectByIdApi = (params) => getAction(baseURL + '/selectById', params);

const getDetailListApi = (params) => getAction(baseURL + '/list', params);
const addDataApi = (params) => postAction(baseURL + '/add', params);


const editDataApi = (params) => putAction(baseURL + '/edit', params);

const deleteByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);

export {
    getPageListApi,
    getDetailListApi,
    getSelectByIdApi,
    addDataApi,
    editDataApi,
    deleteByIdApi
}